import React, { useState } from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import parse from "html-react-parser"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroBanner from "../components/HeroBanner"
import { StyledButton, StyledCloseModalButton } from "../styledComponents/button"
import { HeroDescription, HeroText, HeroHeader } from "../styledComponents/heroBanner"
import { OverviewIntro, LearningBlock, BackgroundColourBlock } from "../styledComponents/overView"
import { ModalProvider, BaseModalBackground } from "styled-react-modal";
import { StyledModal } from "../styledComponents/modal"
import EventList from "../components/eventList";
import Icon from "../components/icon"
import IndexResourceList from "../components/indexResourcesList"

function FancyModalButton(data) {
  const [isOpen, setIsOpen] = useState(false);
  const [opacity, setOpacity] = useState(0);

  function toggleModal(e) {
    setOpacity(0);
    setIsOpen(!isOpen);
  }

  function afterOpen() {
    setTimeout(() => {
      setOpacity(1);
    }, 100);
  }

  function beforeClose() {
    return new Promise((resolve) => {
      setOpacity(0);
      setTimeout(resolve, 300);
    });
  }

  return (
    <>
      <StyledButton onClick={toggleModal}>Learn more</StyledButton>
      <StyledModal
        isOpen={isOpen}
        afterOpen={afterOpen}
        beforeClose={beforeClose}
        onBackgroundClick={toggleModal}
        onEscapeKeydown={toggleModal}
        opacity={opacity}
        backgroundProps={{ opacity }}
        className="index-modal"
      >
       <StyledCloseModalButton onClick={toggleModal}>
          <Icon name="cross" />
        </StyledCloseModalButton>
        {parse(data.data.content)}
      </StyledModal>
    </>
  );
}

const FadingBackground = styled(BaseModalBackground)`
  opacity: ${(props) => props.opacity};
  transition: all 0.3s ease-in-out;
`;


const Home = ({ data, location }) => {
  const posts = data.allWpPost.nodes

    return (
        <Layout location={location} hasHero>
        <SEO title="KaarmiBeing - 7 Steps to your wellbeing" Description="Practical Ways to Improve Your Wellbeing, Health and State of Mind in 6 lessons" />
        <HeroBanner 
          image="/kb-background-1.jpg" 
          backgroundPosition="55% 0" 
          mediumBackgroundPosition="0 -129px" 
          mediumBackgroundSize="130%" 
          largeBackgroundPosition="0 -160px" 
          largeBackgroundSize="130%"
        >
            <HeroText>
              <HeroHeader>
                  {data.wpPage.heroSection.kaarmibeingHeroHeading}
              </HeroHeader>
              <HeroDescription>{data.wpPage.heroSection.kaarmibeingHeroLedeText}</HeroDescription>
              <AnchorLink to="/#overview">Find out more</AnchorLink>
            </HeroText>
        </HeroBanner>

        <OverviewIntro id="overview">
            {parse(data.wpPage.content)}
        </OverviewIntro>

            {data.wpPage.overviewBlocks.kaarmibeingLearningBlocks.map(
                (data, index) => {
                return (
                  <>
                    <BackgroundColourBlock key={data.image.id} number={index}>
                        <LearningBlock number={index}>
                            <figure>
                            <img src={data.image.localFile.publicURL} alt="" width="640" height="430" />
                            </figure>
                            <div className="overviewWrapper">
                                <h2>{data.heading}</h2>
                                {parse(data.lede)}
                                <ModalProvider backgroundComponent={FadingBackground}>
                                    <FancyModalButton data={data}/>
                                </ModalProvider>
                            </div>               
                        </LearningBlock>
                    </BackgroundColourBlock>
                  </>
                )
            })}

            <EventList data={data} page="Level 1" />
        </Layout>
    )
}

export default Home

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      heroSection {
        kaarmibeingHeroBackground {
            uri
            localFile {
                 publicURL
            }
        }
        kaarmibeingHeroHeading
        kaarmibeingHeroLedeText
        kaarmibeingHeroPrimaryCta {
          title
          url
        }
      }
      overviewBlocks {
        fieldGroupName
        kaarmibeingLearningBlocks {
            content
            heading
            lede
            image {
              localFile {
                publicURL
              }
              id
            }
            
        }
      }

      eventsData {
        events {
          date
          time
          timezone
          fieldGroupName
          location
          price
          shortDescription
        }
      }
    }
    allWpPost(sort: { fields: [date], order: DESC }, limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }
}
`
